import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue"; //导入准备好的Home组件
import User from "../views/User.vue"; //导入准备好的User组件
import Main from "../views/Main.vue"; //导入准备好的Main组件
import Login from "../views/Login.vue"; //导入准备好的登录页组件
//1.创建路由组件

Vue.use(VueRouter);

// 2. 定义路由
// 每个路由应该映射一个组件。 其中"component" 可以是
// 通过 Vue.extend() 创建的组件构造器，
// 或者，只是一个组件配置对象。
// 我们晚点再讨论嵌套路由。
const routes = [
  //主路由
  {
    path: "/",
    component: Main,
    name: "Main",
    redirect: "/home",
    children: [
      {
        path: "/songManage/songCustom/addPretendSong",
        component: () => import("@/views/SongManage/CSSongAdd/index.vue"),
        name: "addPretendSong",
        meta: ["曲谱管理", "马甲曲谱添加"],
      },
      // {path:'/home',name:'home',component:Home},  //首页
      // {path:'/user',name:'user',component:User},  //用户管理
      // {path:'/mall',name:'mall',component:Mall},  //商品管理
      // {path:'/page1',name:'page1',component:pageOne},  //商品管理
      // {path:'/page2',name:'page2',component:pageTwo},  //商品管理
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  //   {
  //     path: "/playlistManage/index",
  //     name: "playlistManage",
  //     component: () => import("@/views/PlaylistManage/index.vue"),
  //     meta: {
  //       title: "曲谱管理", // 设置页面标题
  //     },
  //   },
  //   {
  //     path: "/playlistManage/playlistDetail",
  //     name: "playlistDetail",
  //     component: () => import("@/views/PlaylistManage/PlaylistDetail/index.vue"),
  //     meta: {
  //       title: "曲谱详情", // 设置页面标题
  //     },
  //   },
  //   {
  //     path: "/playlistConfig/index",
  //     name: "playlistConfig",
  //     component: () => import("@/views/PlayListConfig/index.vue"),
  //     meta: {
  //       title: "精选歌单", // 设置页面标题
  //     },
  //   },
  //   {
  //     path: "/playlistConfig/featured",
  //     name: "featured",
  //     component: () =>
  //       import("@/views/PlayListConfig/FeaturedPlayList/index.vue"),
  //   },
  //   {
  //     path: "/playlistConfig/square",
  //     name: "square",
  //     component: () => import("@/views/PlayListConfig/PlayListSquare/index.vue"),
  //   },
];

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title; // 设置页面标题
  }
  next();
});

//将router实例对外进行暴露
export default router;
