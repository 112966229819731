<template>
  <div id="app">
    <!-- 路由出口 -->
    <!-- 路由匹配到的组件将渲染在这里 -->
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {},
  created() {
    // 禁用左右滑动导致的前进后退
    if (this.$route.path === "/noSearch") {
      // c2曲谱编辑页
      document.body.style.overscrollBehaviorX = "none";
    }
  },
};
</script>
<!-- 全局样式 -->
<style lang="less">
html,
body,
h3,
p {
  margin: 0;
  padding: 0;
}
</style>
