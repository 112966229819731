<template>
  <div class="login-box">
    <el-form
      ref="form"
      label-width="70px"
      class="login-container"
      :inline="true"
      :model="form"
      :rules="rules"
    >
      <h3 class="login_title">系统登录</h3>
      <el-form-item label="用户名" prop="username">
        <el-input v-model="form.username" placeholder="请输入账号"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="form.password"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="submit"
          type="primary"
          style="margin-left: 105px; margin-top: 10px"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import Mock from "mockjs";
import Cookie from "js-cookie";
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, trigger: "blur", message: "请输入用户名" },
        ],
        password: [{ required: true, trigger: "blur", message: "请输入密码" }],
      },
    };
  },
  methods: {
    //登录
    submit() {
      //token信息
      // const token= Mock.Random.guid()
      // //token信息存入cookie  用于不同页面间的通信
      // Cookie.set('token',token)

      //表单校验通过
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$_api
            .LOGIN_AND_AUTH(this.form.username, this.form.password)
            .then((res) => {
              let data = res.data;
              if (data.code === 1) {
                //token信息存入cookie用于不同页面间的通信
                Cookie.set("token", data.data.token);
                //获取菜单的数据存入store中
                this.$store.commit("setMenu", data.data.menus);
                const permissionNames = data.data.permissions.map(
                  (permission) => permission.ename
                );
                this.$store.commit("setPermissions", permissionNames);
                this.$store.commit("addMenu", this.$router);
                this.$store.commit("setPUserId", data.data.userId);
                //跳转到首页
                if (this.$store.state.tab.root) {
                  this.$router.push("/home");
                  Cookie.set("root", true);
                } else {
                  if (data.data.menus[0].path) {
                    this.$router.push(data.data.menus[0].path);
                  } else {
                    if (data.data.menus[0].children[0].path) {
                      this.$router.push(data.data.menus[0].children[0].path);
                    }
                  }
                  Cookie.set("root", false);
                }
              } else {
                this.$message.error(data.data.message);
              }
            })
            .catch();
        }
      });
    },
  },
};
</script>

<style lang="less">
.login-box {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-container {
  width: 350px;
  border: 1px solid #eaeaea;
  margin: 180px auto;
  padding: 35px 35px 15px 35px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 25px #cac6c6;
  box-sizing: border-box;
  .login_title {
    text-align: center;
    margin-bottom: 40px;
    color: #505458;
  }
  .el-form-item {
    display: flex;
    flex-direction: row;
  }
  .el-input {
    width: 198px;
  }
}
</style>
